import payload_plugin_FEenj74V5b from "/home/runner/work/smz/smz/frontend/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.29.1/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_nLTtBI4myk from "/home/runner/work/smz/smz/frontend/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_i6mecyrtkyytupiffwytdlu5di/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_0MeYdRkcPG from "/home/runner/work/smz/smz/frontend/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_i6mecyrtkyytupiffwytdlu5di/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_WX4eSQGdrJ from "/home/runner/work/smz/smz/frontend/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_i6mecyrtkyytupiffwytdlu5di/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_SaNqtgjI2e from "/home/runner/work/smz/smz/frontend/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_i6mecyrtkyytupiffwytdlu5di/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_XVVZfH01a4 from "/home/runner/work/smz/smz/frontend/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_i6mecyrtkyytupiffwytdlu5di/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_dOLZjqLdSN from "/home/runner/work/smz/smz/frontend/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_i6mecyrtkyytupiffwytdlu5di/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_8QSmS8jcMw from "/home/runner/work/smz/smz/frontend/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_i6mecyrtkyytupiffwytdlu5di/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_zFNWPvCEKK from "/home/runner/work/smz/smz/frontend/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_i6mecyrtkyytupiffwytdlu5di/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_8HhU3kFDBp from "/home/runner/work/smz/smz/frontend/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.29.1/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/smz/smz/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_rYMeWVG9E5 from "/home/runner/work/smz/smz/frontend/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_i6mecyrtkyytupiffwytdlu5di/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_wy0B721ODc from "/home/runner/work/smz/smz/frontend/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import _01_tabler_icons_9mYbrP432H from "/home/runner/work/smz/smz/frontend/plugins/01.tabler-icons.ts";
import _02_vuetify_rwZ6VQqJQJ from "/home/runner/work/smz/smz/frontend/plugins/02.vuetify.ts";
import scrollbar_client_afVkuO58C3 from "/home/runner/work/smz/smz/frontend/plugins/scrollbar.client.ts";
import user_client_pgylpKXiFi from "/home/runner/work/smz/smz/frontend/plugins/user.client.ts";
export default [
  payload_plugin_FEenj74V5b,
  revive_payload_client_nLTtBI4myk,
  unhead_0MeYdRkcPG,
  router_WX4eSQGdrJ,
  payload_client_SaNqtgjI2e,
  navigation_repaint_client_XVVZfH01a4,
  check_outdated_build_client_dOLZjqLdSN,
  view_transitions_client_8QSmS8jcMw,
  chunk_reload_client_zFNWPvCEKK,
  plugin_vue3_8HhU3kFDBp,
  components_plugin_KR1HBZs4kY,
  prefetch_client_rYMeWVG9E5,
  plugin_wy0B721ODc,
  _01_tabler_icons_9mYbrP432H,
  _02_vuetify_rwZ6VQqJQJ,
  scrollbar_client_afVkuO58C3,
  user_client_pgylpKXiFi
]