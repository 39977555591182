<script setup lang="ts">
import type { CardMini } from '~/api/card/models/card-mini'
import { CircleIcon, Message2Icon, UsersIcon } from 'vue-tabler-icons'
import CounterChip from '~/components/core/icons/CounterChip.vue'
import { timeFormat } from '~/composables/utils'

const { card } = defineProps<{ card: CardMini }>()

// import type { MemberDto } from '~/api/card/models/member-dto'

// TODO:
const topic = 'Near from you'
const readTime = '2 min read'

const showMessageCount = ref<boolean>(false)
</script>

<template>
  <v-card :to="`/card/${card.id}`" elevation="10" class="card-hover" link hover height="100%">
    <v-img :src="card?.img" height="250px" alt="post" cover class="rounded-t-md align-end text-right">
      <v-card-item>
        <v-chip class="bg-surface text-body-2" size="small">
          <!-- TODO: -->
          {{ readTime }}
        </v-chip>
      </v-card-item>
    </v-img>

    <v-avatar size="40" class="mt-n7 mx-6">
      <v-img :src="card.owner!!.avatar" cover alt="icon" height="40" />
    </v-avatar>

    <v-row no-gutters class="px-6 py-4" align-content="space-between" justify="space-between">
      <v-col cols="12" class="title-block-height">
        <v-chip class="text-body-2 font-weight-medium bg-grey100" size="small">
          <!-- TODO: {{ post?.category }} -->
          {{ topic }}
        </v-chip>

        <h5 class="text-h5 text-13 my-6">
          {{ card.title }}
        </h5>
      </v-col>

      <v-col cols="12" class="">
        <footer class="d-flex justify-space-between ">
          <div>
            <CounterChip :icon="UsersIcon" :value="card.membersCount" />
            <CounterChip v-if="showMessageCount" :icon="Message2Icon" :value="13" class="ml-4" />
          </div>
          <div>
            <v-icon size="10">
              <CircleIcon size="10" />
            </v-icon>
            <span class="text-subtitle-2 ml-2">
              <!-- todo: computed of start/startED (if in past)  -->
              <span class="font-weight-thin">start: </span> {{ timeFormat(card.startDate!!) }}
            </span>
          </div>
        </footer>
      </v-col>
    </v-row>
  </v-card>
</template>

<style scoped lang="scss">
.title-block-height {
  height: 9rem;
}
</style>
