<script setup lang="ts">
import type { MemberDto } from '~/api/card/models/member-dto'

const { members } = defineProps<{ members: MemberDto[] }>()

// TODO
function isOnline(index: number) {
  return index % 2 === 0
}
</script>

<template>
  <section class="px-6 py-4 mt-n5 d-flex align-center overflow-hidden">
    <v-badge v-for="(member, index) in members" :key="index" :model-value="isOnline(index)" color="secondary" dot>
      <v-avatar class="ml-n2 avatar-border" :class="isOnline(index) ? 'glow' : ''">
        <v-img :src="member.avatar" cover alt="user icon">
          <v-tooltip activator="parent" location="bottom">
            {{ member.nick }}
          </v-tooltip>
        </v-img>
      </v-avatar>
    </v-badge>
  </section>
</template>

<style scoped lang="scss">
.avatar-border {
  border: 1px solid rgb(var(--v-theme-surface)) !important;
}

.glow {
  box-shadow: 0 0 2px 1px rgb(var(--v-theme-primary));
  animation: glow 2s linear infinite alternate;
}

@keyframes glow {
  to {
    box-shadow: 0 0 10px 3px rgb(var(--v-theme-primary));
  }
}
</style>
