<script setup lang="ts">
import type ListItem from '~/components/core/types/list'
import { BellRingingIcon } from 'vue-tabler-icons'

const staticP = `${useRuntimeConfig().public.STATIC}/img/profile`

const user1 = `${staticP}/user-1.jpg`
const user2 = `${staticP}/user-2.jpg`
const user3 = `${staticP}/user-3.jpg`
const user4 = `${staticP}/user-4.jpg`
const user5 = `${staticP}/user-5.jpg`

const notifications: ListItem[] = [
  {
    icon: user1,
    title: 'Roman Joined the Team!',
    subtitle: 'Congratulate him',
  },
  {
    icon: user2,
    title: 'New message received',
    subtitle: 'Salma sent you new message',
  },
  {
    icon: user3,
    title: 'New Payment received',
    subtitle: 'Check your earnings',
  },
  {
    icon: user4,
    title: 'Jolly completed tasks',
    subtitle: 'Assign her new tasks',
  },
  {
    icon: user5,
    title: 'New Payment received',
    subtitle: 'Check your earnings',
  },
]
</script>

<template>
  <v-menu :close-on-content-click="false">
    <template #activator="{ props }">
      <v-btn icon variant="text" class="custom-hover-primary" v-bind="props">
        <v-badge dot color="accent">
          <BellRingingIcon size="22" />
        </v-badge>
      </v-btn>
    </template>

    <v-sheet rounded="md" width="360" elevation="10">
      <section class="px-6 py-4">
        <div class="d-flex  align-center justify-space-between">
          <div class="text-h6">
            Notifications
          </div>
          <div>
            <v-chip color="primary" variant="flat" size="small" class="text-white">
              5 New
            </v-chip>
          </div>
        </div>
      </section>

      <perfect-scrollbar class="scroll">
        <v-list class="py-0 theme-list" lines="two">
          <v-list-item v-for="item in notifications" :key="item.title" class="pa-4" :value="item" color="primary">
            <template #prepend>
              <v-avatar size="48" class="mr-3">
                <v-img :src="item.icon" width="48" :alt="item.icon" />
              </v-avatar>
            </template>
            <h6 class="text-subtitle-1 font-weight-bold mb-1">
              {{ item.title }}
            </h6>
            <p class="text-subtitle-1 font-weight-regular text-disabled">
              {{ item.subtitle }}
            </p>
          </v-list-item>
          <v-divider />
        </v-list>
      </perfect-scrollbar>

      <div class="py-4 px-6 text-center">
        <v-btn color="primary" variant="outlined" block>
          See all Notifications
        </v-btn>
      </div>
    </v-sheet>
  </v-menu>
</template>

<style scoped lang="scss">
.scroll {
  height: 50vh;
}
</style>
