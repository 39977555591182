<script setup lang="ts">
import type { MemberDto } from '~/api/card/models/member-dto'
import { UsersIcon } from 'vue-tabler-icons'

const { members } = defineProps<{ members: MemberDto[] }>()

// TODO
const size = 65

// TODO
function isOnline(index: number) {
  return index % 2 === 0
}

function sizeShift(index: number): number {
  switch (index) {
    case 1:
      return 1.4
    case 2:
      return 1.1
    case 3:
      return 0.8
    case 4:
      return 1.8
    case 5:
      return 1.4
    case 6:
      return 0.7
    case 7:
      return 1.5
    case 8:
      return 1.1
    default:
      return 1
  }
}

function marginLeftShift(index: number): string {
  switch (index) {
    case 1:
      return '1'
    case 2:
      return '4'
    case 3:
      return '1'
    case 4:
      return 'n3'
    case 5:
      return 'n4'
    case 6:
      return '4'
    case 7:
      return '4'
    case 8:
      return 'n6'
    case 9:
      return '3'
    default:
      return 'n2'
  }
}

function marginYShift(index: number): string {
  switch (index) {
    case 1:
      return 'mb-n8'
    case 2:
      return 'mt-n2'
    case 3:
      return 'mt-n12'
    case 4:
      return 'mb-1'
    case 5:
      return 'mb-n10'
    case 7:
      return 'mt-n8'
    case 8:
      return 'mb-n10'
    default:
      return ''
  }
}
</script>

<template>
  <v-card elevation="10" class="border-thin" height="175">
    <v-card-title class="d-flex flex-wrap align-center justify-space-between">
      <h5 class="text-h5">
        Fresh colabers 🥳
      </h5>
      <v-icon class="cursor-pointer">
        <UsersIcon />
      </v-icon>
    </v-card-title>

    <section class="px-6 py-3 mt-n8 d-flex overflow-x-hidden">
      <v-avatar
        v-for="(member, index) in members" :key="index"
        :size="size * sizeShift(index)"
        class="avatar-border align-self-center"
        :class="[
          isOnline(index) ? 'glow' : '',
          `ml-${marginLeftShift(index)}`, marginYShift(index),
        ]"
      >
        <v-img :src="member.avatar" cover>
          <v-tooltip activator="parent" location="bottom">
            {{ member.nick }}
          </v-tooltip>
        </v-img>
      </v-avatar>
    </section>
  </v-card>
</template>

<style scoped lang="scss">
.avatar-border {
  border: 2px solid rgb(var(--v-theme-surface)) !important;
}

.glow {
  box-shadow: 0 0 2px 1px rgb(var(--v-theme-primary));
  animation: glow 2s linear infinite alternate;
}

@keyframes glow {
  to {
    box-shadow: 0 0 10px 3px rgb(var(--v-theme-primary));
  }
}
</style>
