import type { MemberDto } from '~/api/card/models/member-dto'
import { defineStore } from 'pinia'
import { reactive, ref } from 'vue'
import { UserControllerApi } from '~/api/auth/controllers/user-controller-api'

export const useMemberStore = defineStore('members', () => {
  const members = reactive<Map<number, MemberDto>>(new Map())
  const newMembers = reactive<MemberDto[]>([])
  const chosenMember = ref<MemberDto>()

  function addMember(member: MemberDto) {
    members.set(member.id, member)
    console.log(`Adding Member id = ${member.id}`)
  }

  async function getNewMembers(limit: number = 10) {
    if (newMembers.length === 0) {
      (await new UserControllerApi().findFreshUsers(limit)).data.forEach((user: MemberDto) => newMembers.push(user))
    }
    return newMembers
  }

  function chooseMember(member: MemberDto | undefined): void {
    if (!member)
      return

    chosenMember.value = member
    console.log(`📩Chosen Member: ${chosenMember.value?.id}`)
  }

  return {
    members,
    chosenMember,
    getNewMembers,
    newMembers,
    addMember,
    chooseMember,
  }
})
