/* tslint:disable */
/* eslint-disable */
/**
 * Colaba AUTH
 * Authorization server API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@colaba.online
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { Address } from '../models';
// @ts-ignore
import type { GetUser400Response } from '../models';
// @ts-ignore
import type { GetUser405Response } from '../models';
/**
 * AddressControllerApi - axios parameter creator
 * @export
 */
export const AddressControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmAddress: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/address/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyAddress: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/address`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} city 
         * @param {number} lat 
         * @param {number} lng 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCity: async (city: string, lat: number, lng: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'city' is not null or undefined
            assertParamExists('updateCity', 'city', city)
            // verify required parameter 'lat' is not null or undefined
            assertParamExists('updateCity', 'lat', lat)
            // verify required parameter 'lng' is not null or undefined
            assertParamExists('updateCity', 'lng', lng)
            const localVarPath = `/address/city/{city}`
                .replace(`{${"city"}}`, encodeURIComponent(String(city)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lat !== undefined) {
                localVarQueryParameter['lat'] = lat;
            }

            if (lng !== undefined) {
                localVarQueryParameter['lng'] = lng;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} country 
         * @param {string} countryIsoCode 
         * @param {number} lat 
         * @param {number} lng 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCountry: async (country: string, countryIsoCode: string, lat: number, lng: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'country' is not null or undefined
            assertParamExists('updateCountry', 'country', country)
            // verify required parameter 'countryIsoCode' is not null or undefined
            assertParamExists('updateCountry', 'countryIsoCode', countryIsoCode)
            // verify required parameter 'lat' is not null or undefined
            assertParamExists('updateCountry', 'lat', lat)
            // verify required parameter 'lng' is not null or undefined
            assertParamExists('updateCountry', 'lng', lng)
            const localVarPath = `/address/country/{countryIsoCode}/{country}`
                .replace(`{${"country"}}`, encodeURIComponent(String(country)))
                .replace(`{${"countryIsoCode"}}`, encodeURIComponent(String(countryIsoCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lat !== undefined) {
                localVarQueryParameter['lat'] = lat;
            }

            if (lng !== undefined) {
                localVarQueryParameter['lng'] = lng;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} street 
         * @param {number} lat 
         * @param {number} lng 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStreet: async (street: string, lat: number, lng: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'street' is not null or undefined
            assertParamExists('updateStreet', 'street', street)
            // verify required parameter 'lat' is not null or undefined
            assertParamExists('updateStreet', 'lat', lat)
            // verify required parameter 'lng' is not null or undefined
            assertParamExists('updateStreet', 'lng', lng)
            const localVarPath = `/address/street/{street}`
                .replace(`{${"street"}}`, encodeURIComponent(String(street)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lat !== undefined) {
                localVarQueryParameter['lat'] = lat;
            }

            if (lng !== undefined) {
                localVarQueryParameter['lng'] = lng;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AddressControllerApi - functional programming interface
 * @export
 */
export const AddressControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AddressControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmAddress(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmAddress(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddressControllerApi.confirmAddress']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyAddress(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Address>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyAddress(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddressControllerApi.getMyAddress']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} city 
         * @param {number} lat 
         * @param {number} lng 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCity(city: string, lat: number, lng: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCity(city, lat, lng, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddressControllerApi.updateCity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} country 
         * @param {string} countryIsoCode 
         * @param {number} lat 
         * @param {number} lng 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCountry(country: string, countryIsoCode: string, lat: number, lng: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCountry(country, countryIsoCode, lat, lng, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddressControllerApi.updateCountry']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} street 
         * @param {number} lat 
         * @param {number} lng 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStreet(street: string, lat: number, lng: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStreet(street, lat, lng, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddressControllerApi.updateStreet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AddressControllerApi - factory interface
 * @export
 */
export const AddressControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AddressControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmAddress(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.confirmAddress(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyAddress(options?: RawAxiosRequestConfig): AxiosPromise<Address> {
            return localVarFp.getMyAddress(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} city 
         * @param {number} lat 
         * @param {number} lng 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCity(city: string, lat: number, lng: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateCity(city, lat, lng, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} country 
         * @param {string} countryIsoCode 
         * @param {number} lat 
         * @param {number} lng 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCountry(country: string, countryIsoCode: string, lat: number, lng: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateCountry(country, countryIsoCode, lat, lng, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} street 
         * @param {number} lat 
         * @param {number} lng 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStreet(street: string, lat: number, lng: number, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateStreet(street, lat, lng, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AddressControllerApi - object-oriented interface
 * @export
 * @class AddressControllerApi
 * @extends {BaseAPI}
 */
export class AddressControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressControllerApi
     */
    public confirmAddress(options?: RawAxiosRequestConfig) {
        return AddressControllerApiFp(this.configuration).confirmAddress(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressControllerApi
     */
    public getMyAddress(options?: RawAxiosRequestConfig) {
        return AddressControllerApiFp(this.configuration).getMyAddress(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} city 
     * @param {number} lat 
     * @param {number} lng 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressControllerApi
     */
    public updateCity(city: string, lat: number, lng: number, options?: RawAxiosRequestConfig) {
        return AddressControllerApiFp(this.configuration).updateCity(city, lat, lng, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} country 
     * @param {string} countryIsoCode 
     * @param {number} lat 
     * @param {number} lng 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressControllerApi
     */
    public updateCountry(country: string, countryIsoCode: string, lat: number, lng: number, options?: RawAxiosRequestConfig) {
        return AddressControllerApiFp(this.configuration).updateCountry(country, countryIsoCode, lat, lng, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} street 
     * @param {number} lat 
     * @param {number} lng 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressControllerApi
     */
    public updateStreet(street: string, lat: number, lng: number, options?: RawAxiosRequestConfig) {
        return AddressControllerApiFp(this.configuration).updateStreet(street, lat, lng, options).then((request) => request(this.axios, this.basePath));
    }
}

