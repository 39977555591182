/* tslint:disable */
/* eslint-disable */
/**
 * card
 * card API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CardES } from '../models';
/**
 * ElasticCardControllerApi - axios parameter creator
 * @export
 */
export const ElasticCardControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cards searcher
         * @param {string} chars 
         * @param {boolean} [highlight] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCardsInElastic: async (chars: string, highlight?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chars' is not null or undefined
            assertParamExists('searchCardsInElastic', 'chars', chars)
            const localVarPath = `/search/{chars}`
                .replace(`{${"chars"}}`, encodeURIComponent(String(chars)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (highlight !== undefined) {
                localVarQueryParameter['highlight'] = highlight;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tags searcher
         * @param {string} chars 
         * @param {string} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTagsInElastic: async (chars: string, limit?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chars' is not null or undefined
            assertParamExists('searchTagsInElastic', 'chars', chars)
            const localVarPath = `/search/tags/{chars}`
                .replace(`{${"chars"}}`, encodeURIComponent(String(chars)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ElasticCardControllerApi - functional programming interface
 * @export
 */
export const ElasticCardControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ElasticCardControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cards searcher
         * @param {string} chars 
         * @param {boolean} [highlight] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchCardsInElastic(chars: string, highlight?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CardES>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchCardsInElastic(chars, highlight, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ElasticCardControllerApi.searchCardsInElastic']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Tags searcher
         * @param {string} chars 
         * @param {string} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchTagsInElastic(chars: string, limit?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchTagsInElastic(chars, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ElasticCardControllerApi.searchTagsInElastic']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ElasticCardControllerApi - factory interface
 * @export
 */
export const ElasticCardControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ElasticCardControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Cards searcher
         * @param {string} chars 
         * @param {boolean} [highlight] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCardsInElastic(chars: string, highlight?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<Array<CardES>> {
            return localVarFp.searchCardsInElastic(chars, highlight, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tags searcher
         * @param {string} chars 
         * @param {string} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTagsInElastic(chars: string, limit?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<string>> {
            return localVarFp.searchTagsInElastic(chars, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ElasticCardControllerApi - object-oriented interface
 * @export
 * @class ElasticCardControllerApi
 * @extends {BaseAPI}
 */
export class ElasticCardControllerApi extends BaseAPI {
    /**
     * 
     * @summary Cards searcher
     * @param {string} chars 
     * @param {boolean} [highlight] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElasticCardControllerApi
     */
    public searchCardsInElastic(chars: string, highlight?: boolean, options?: RawAxiosRequestConfig) {
        return ElasticCardControllerApiFp(this.configuration).searchCardsInElastic(chars, highlight, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tags searcher
     * @param {string} chars 
     * @param {string} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElasticCardControllerApi
     */
    public searchTagsInElastic(chars: string, limit?: string, options?: RawAxiosRequestConfig) {
        return ElasticCardControllerApiFp(this.configuration).searchTagsInElastic(chars, limit, options).then((request) => request(this.axios, this.basePath));
    }
}

