import validate from "/home/runner/work/smz/smz/frontend/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_i6mecyrtkyytupiffwytdlu5di/node_modules/nuxt/dist/pages/runtime/validate.js";
import always_45run_45global from "/home/runner/work/smz/smz/frontend/middleware/always-run.global.ts";
import manifest_45route_45rule from "/home/runner/work/smz/smz/frontend/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_i6mecyrtkyytupiffwytdlu5di/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  always_45run_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "admin-only": () => import("/home/runner/work/smz/smz/frontend/middleware/admin-only.ts"),
  "guest-only": () => import("/home/runner/work/smz/smz/frontend/middleware/guest-only.ts"),
  "user-only": () => import("/home/runner/work/smz/smz/frontend/middleware/user-only.ts")
}